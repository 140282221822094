import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { MediaModel } from '../models/media.model';
import { SettingsService } from '../services/settings.service';
import { SettingsModel } from '../models/setting.model';

@Pipe({ name: 'apiImage', standalone: true })
export class ApiImagePipe implements PipeTransform {
  apiDomain = environment.apiDomain;
  isMobile = false;
  settings = new SettingsModel();

  constructor(
    private settingsService: SettingsService,
    @Optional() @Inject('mobile') protected mobile: boolean,
  ) {
    this.isMobile = mobile;
  }

  transform(
    imageUrl: string,
    imageWidth: number,
    image: MediaModel,
    mode: string = 'fill',
    imageHeight: number = 0,
    fixedHeight = false,
    quality = 100,
  ) {
    // console.log('imageUrl :>> ', imageUrl);
    // console.log('imageWidth :>> ', imageWidth);
    // console.log('image.ImageRatio :>> ', image.ImageRatio);
    // console.log('image :>> ', image);
    let clientId = this.settings.ClientId;

    this.isMobile = this.settingsService.getMobileStatus();
    const imageType = image.PageType;

    if (!imageUrl) {
      return;
    }

    imageUrl = this.imgFxPath(imageType ?? 0, clientId) + imageUrl;

    // fix mobile images
    if (this.isMobile) {
      if (imageWidth > 720) {
        imageWidth = 720;
      }
    }

    let size = '';
    if (imageWidth !== 0 && image.ImageRatio && image.ImageRatio > 0) {
      const imagePath = environment.imgFxActive
        ? environment.imgFxDomain.replace('{mode}', mode + '.webp') + imageUrl
        : environment.apiDomain + imageUrl;
      if (imageHeight > 0) {
        size = imageWidth + 'x' + imageHeight;
        return imagePath.replace(
          '{size}',
          size + (quality < 100 ? '.q-' + quality : ''),
        );
      }
      if (fixedHeight) {
        size = '1200x630';
        return imagePath.replace('{size}', size);
      }
      size = imageWidth + 'x' + Math.floor(imageWidth * image.ImageRatio);
      return imagePath.replace(
        '{size}',
        size + (quality < 100 ? '.q-' + quality : ''),
      );
    }

    return imageUrl.substr(0, 4) === 'http'
      ? imageUrl
      : this.apiDomain + imageUrl;
  }

  imgFxPath(imageType: number, clientId: number) {
    if (environment.imgFxActive) {
      switch (imageType) {
        case 1:
          return clientId === 1 ? 'destination/' : 'destinasjon/';
        case 2:
          return 'hotell/';
        case 3:
          return clientId === 1 ? 'destination/' : 'destinasjon/';
        case 4:
          return clientId === 1 ? 'sevardhet/' : 'severdighet/';
        case 5:
          return clientId === 1 ? 'sevardhet/' : 'severdighet/';
        case 6:
          return clientId === 1 ? 'startsida/' : 'startside/';
        case 7:
          return clientId === 1 ? 'installningar/' : 'installningar/';
        case 8:
          return clientId === 1 ? 'startsida/' : 'startside/';
        case 9:
          return clientId === 1 ? 'staticpage/' : 'staticpage/';
        case 10:
          return clientId === 1 ? 'destination/' : 'destinasjon/';
        default:
          return 'redigering/';
      }
    }

    switch (imageType) {
      case 1:
        return 'upload/destinasjon/';
      case 2:
        '';
        return 'upload/property/';
      case 3:
        return 'upload/destinasjon/';
      case 4:
        return 'upload/poi/';
      case 5:
        return 'upload/poi/';
      case 6:
        return 'upload/frontpage/';
      case 7:
        return 'upload/settings/';
      case 8:
        return 'upload/frontpage/';
      default:
        return 'upload/editor/';
    }
  }
}
